/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

import { videoIdVimeo } from 'utils/pattern-utils';
import { FarmstandReviewed, Featured } from 'components';
import { LgType } from 'constants/LgType';

import {
  HeroFeatured,
  RecipeSection,
  FeaturedSeedlings,
  QuoteSection,
  BannerCallOut,
  TermsAndConditions,
  HarvestProductBundlesSection,
  HarvestProductFarmBundleSection,
  HarvestRedemptionShop,
} from './components';

const sectionsRenderer = (slug, farmstandSize, redemptionCode) => {
  return {
    [LgType.HARVEST.HERO]: ({ title, body, contentBlock, sectionType }) => (
      <HeroFeatured key={LgType.HARVEST.HERO} title={title} body={body} imageContent={contentBlock} modifiers={sectionType} />
    ),
    [LgType.HARVEST.SHOP_BUNDLES]: ({ title, body, percentages }) => {
      return (
        <HarvestProductBundlesSection
          title={title}
          body={body}
          sections={percentages}
          slug={slug}
          farmstandSize={farmstandSize ? +farmstandSize : null}
        />
      );
    },
    [LgType.HARVEST.SHOP_FARM_BUNDLES]: ({ title, body, percentages }) => {
      return (
        <HarvestProductFarmBundleSection
          title={title}
          body={body}
          sections={percentages}
          slug={slug}
          farmstandSize={farmstandSize ? +farmstandSize : null}
        />
      );
    },
    [LgType.HARVEST.SHOP_REDEMPTION]: ({ title, body, percentages }) => {
      return (
        <HarvestRedemptionShop
          title={title}
          body={body}
          sections={percentages}
          slug={slug}
          farmstandSize={farmstandSize ? +farmstandSize : null}
          redemptionCode={redemptionCode}
        />
      );
    },
    [LgType.HARVEST.RECIPES]: ({ title, body, percentages }) => {
      const recipes = percentages?.filter?.(({ sys }) => sys.contentType.sys.id === 'recipe');
      const quote = percentages?.find?.(({ sys }) => sys.contentType.sys.id !== 'recipe');

      return <RecipeSection key={LgType.HARVEST.RECIPES} title={title} body={body} quote={quote?.fields} recipes={recipes} />;
    },
    [LgType.HARVEST.FEATURED_SEEDLINGS]: ({ title, lowerTitle, body, percentages }) => (
      <FeaturedSeedlings
        key={LgType.HARVEST.FEATURED_SEEDLINGS}
        title={title}
        subtitle={lowerTitle}
        labelTip={body}
        seedlings={percentages}
      />
    ),
    [LgType.HARVEST.SMOOTHIES]: ({ title, lowerTitle, body, percentages, slug }) => (
      <FeaturedSeedlings
        modifiers='smoothieSection'
        key={LgType.HARVEST.SMOOTHIES}
        title={title}
        subtitle={lowerTitle}
        body={body}
        seedlings={percentages}
        slug={slug}
        isRecipe
      />
    ),
    [LgType.HARVEST.QUOTE]: ({ title, headline, body, body2, mediaContent, subSectionHeaders: links }) => (
      <QuoteSection
        key={LgType.HARVEST.QUOTE}
        title={title}
        subtitle={headline}
        body={body}
        image={mediaContent?.fields?.file?.url}
        imageDescription={mediaContent?.fields?.description}
        links={links}
        modifiers={body2}
      />
    ),
    [LgType.HARVEST.PODCAST]: ({ title, body, percentages: links, callToAction }) => (
      <BannerCallOut key={LgType.HARVEST.PODCAST} title={title} body={body} links={links} callToAction={callToAction} />
    ),
    [LgType.HARVEST.VIDEO]: ({ title, body, video }) => (
      <FarmstandReviewed key={LgType.HARVEST.VIDEO} title={title} body={body} video={videoIdVimeo(video)} modifiers='white' />
    ),
    [LgType.HARVEST.FAQ]: ({ title, percentages }) => (
      <Featured key={LgType.HARVEST.FAQ} title={title} percentages={percentages} modifiers='gray_4' shouldShowAll />
    ),
    [LgType.HARVEST.TERMS]: ({ body }) => <TermsAndConditions key={LgType.HARVEST.TERMS} body={body} />,
  };
};

export default sectionsRenderer;

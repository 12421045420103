import React from 'react';
import PropTypes from 'prop-types';

import { Image, Title, Text, Link } from 'elements';

import { SeedlingWrapper, SeedlingInner, SeedlingInfoWrapper, SeedlingImageWrapper } from './FeaturedSeedlings.styled';

import paths from 'constants/paths';

const Seedling = ({ title, body, labelTip, image, imageAlt, slug }) => {
  const recipeLink = slug ? `${paths.RECIPES}/${slug}` : null;

  return (
    <SeedlingWrapper>
      <SeedlingInner>
        <SeedlingImageWrapper>
          <Image
            picture={image}
            imageRouteLink={recipeLink}
            alt={imageAlt || labelTip}
          />
        </SeedlingImageWrapper>
        <SeedlingInfoWrapper>
          <Title as='h3' content={labelTip} />
          <Title as='h4' content={title} />
          <Text content={body} />
          {!!recipeLink && <Link modifiers={['brandColor', 'underline']} content='See Recipe' href={recipeLink} />}
        </SeedlingInfoWrapper>
      </SeedlingInner>
    </SeedlingWrapper>
  );
};

Seedling.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  labelTip: PropTypes.string,
  image: PropTypes.string,
  slug: PropTypes.string,
};

export default Seedling;

import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const HarvestPlanProductStyled = styled.div.attrs({
  className: 'HarvestPlanProductStyled',
})`
  @media ${breakPoints.large_break} {
    .HeroSection {
      max-height: 41rem;
      background-color: ${({ theme }) => theme.farmProject.gray_1};
    }
  }

  .FarmstandReviewedStyled {
    .ContentSection {
      background-color: ${({ theme }) => theme.farmProject.light_green_3};
      padding: 3rem 2rem;
    }

    @media ${breakPoints.large_break} {
      .ContentSection {
        .SectionInner {
          .ResponsiveEmbed {
            height: 25rem;
            width: 44rem;
            padding: 0;
            overflow: visible;
          }
        }
      }
    }
  }
`;

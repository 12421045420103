import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Image, Title, Text, Link, Icon } from 'elements';

import { QuoteSectionStyled, QuoteSectionInner, PictureWrapper, QuoteWrapper, IconLinkWrapper } from './QuoteSection.styled';

import Quote from 'elements/icon/assets/quote.svg';
import Seedling from 'elements/icon/assets/seedling-new.svg';
import ForkSpoon from 'elements/icon/assets/fork-spoon.svg';

import scrollIntoView from 'utils/scrollIntoView';

const SEE_RECIPES_LINK_ID = 'pVwieOfzfglDwHQDjTCpa';
const SHOP_HARVEST_ID = '1QkvduxsyPPYFBROTKqFNr';

const CHIPOTLE_RECIPES_LINK_ID = '3XmLBXXEr6d5mJ0IfElU2H';
const CHIPOTLE_SHOP_LINK_ID = '4RU7x5fgygXsJnByyG7s5q';

const MATTJAMES_RECIPES_LINK_ID = '4vW86gJKvnZvrNZG0QCOE6';
const MATTJAMES_SHOP_LINK_ID = '4QYNbK0WtcGHxKVWTctqip';

const BRIGHTLAND_RECIPES_LINK_ID = '1BYhYx0CYLSV6TPHF7QiXv';
const BRIGHTLAND_SHOP_LINK_ID = 'W8ZJmyPmx3Xou46rrwPGN';

const KNOT_RECIPES_LINK_ID = '6CGkSOR2ct9E6rkF2cxY1A';
const KNOT_SHOP_LINK_ID = '1Cw1TXZZfiDurKAXeQZ2jr';

const recipesLinksIds = [
  CHIPOTLE_RECIPES_LINK_ID,
  SEE_RECIPES_LINK_ID,
  MATTJAMES_RECIPES_LINK_ID,
  BRIGHTLAND_RECIPES_LINK_ID,
  KNOT_RECIPES_LINK_ID,
];

const shopLinksIds = [SHOP_HARVEST_ID, CHIPOTLE_SHOP_LINK_ID, MATTJAMES_SHOP_LINK_ID, BRIGHTLAND_SHOP_LINK_ID, KNOT_SHOP_LINK_ID];

const getIconById = (id) => {
  if (recipesLinksIds.includes(id)) return <Seedling />;
  else if (shopLinksIds.includes(id)) return <ForkSpoon />;
  return null;
};

const QuoteSection = ({ title, subtitle, body, image, imageDescription, links, modifiers }) => {
  const onScrollToSection = (linkId) => {
    const sectionId = recipesLinksIds.includes(linkId) ? 'recipes' : 'harvest';
    const section = document.querySelector(`#${sectionId}`);
    if (section) scrollIntoView({ hash: `#${sectionId}` }, section, 15);
  };

  const renderLink = (item, key) => {
    const { linkText } = item?.fields;
    const onClick = () => onScrollToSection(item?.sys?.id);

    return (
      <IconLinkWrapper key={key} onClick={onClick}>
        <Icon>{getIconById(item?.sys?.id)}</Icon>
        <Link modifiers={['brandColor', 'underline']} content={linkText} />
      </IconLinkWrapper>
    );
  };

  return (
    <QuoteSectionStyled modifiers={modifiers}>
      <ContentSection>
        <QuoteSectionInner>
          <PictureWrapper>
            <Image picture={image} alt={imageDescription} />
          </PictureWrapper>
          <QuoteWrapper>
            <Icon>
              <Quote />
            </Icon>
            <Title as='h2' content={title} modifiers='tertiarySmall' />
            <Text content={subtitle} modifiers={['brandFont', 'bold']} />
            <Text>{body}</Text>
            {links?.map?.(renderLink)}
          </QuoteWrapper>
        </QuoteSectionInner>
      </ContentSection>
    </QuoteSectionStyled>
  );
};

QuoteSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
  modifiers: PropTypes.string,
  image: PropTypes.string,
  links: PropTypes.array,
};

export default QuoteSection;

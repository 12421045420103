import React from 'react';
import PropTypes from 'prop-types';

import { ImageSlider } from 'components';
import { Title, Text, Link, Icon } from 'elements';

import { RecipeCardStyled, SliderWrapper, RecipeInfoWrapper, TimeWrapper } from './RecipeSection.styled';

import Clock from 'elements/icon/assets/clock.svg';

import paths from 'constants/paths';

const RecipeCard = ({ title, body, images, time, author, slug }) => {
  return (
    <RecipeCardStyled>
      <SliderWrapper>
        <ImageSlider images={images} />
      </SliderWrapper>
      <RecipeInfoWrapper>
        <TimeWrapper>
          <Icon>
            <Clock />
          </Icon>
          <Text as='span' modifiers={['brandFont', 'lightGrayColor', 'semibold']} content={time} />
        </TimeWrapper>
        <Title as='h2' content={title} modifiers='tertiarySmall' />
        <Text as='span' modifiers={['brandFont', 'lightGrayColor', 'semibold']} content={`By ${author}`} />
        <Text content={body} isHTML />
        <Link modifiers={['brandColor', 'underline']} content='See Recipe' href={`${paths.RECIPES}/${slug}`} />
      </RecipeInfoWrapper>
    </RecipeCardStyled>
  );
};

RecipeCard.propTypes = {
  images: PropTypes.array,
  title: PropTypes.string,
  body: PropTypes.string,
  time: PropTypes.string,
  author: PropTypes.string,
  slug: PropTypes.string,
};

export default RecipeCard;

/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Head from 'components/Head';

import { HarvestPlanProductStyled } from './HarvestPlanProduct.styled';
import { SectionProvider } from 'components';

import pageSpecificMap from './SectionsRenderer';

import { useCatalog, useStopLoading, useContent } from 'utils/hooks';
import {
  ZOOEY_HARVEST_PLAN,
  CHIPOTLE_HARVEST_PLAN,
  MATTJAMES_HARVEST_PLAN,
  BRIGHTLAND_HARVEST_PLAN,
  KNOT_HARVEST_PLAN,
  THRIVE_HARVEST_PLAN,
  COSTCO_HARVEST_PLAN,
  AMAZON_HARVEST_PLAN,
} from 'constants/contentful';
import paths from 'constants/paths';

const mapContentByPartner = {
  [paths.HARVEST_PLAN.ZOOEY]: ZOOEY_HARVEST_PLAN,
  [paths.HARVEST_PLAN.CHIPOTLE]: CHIPOTLE_HARVEST_PLAN,
  [paths.HARVEST_PLAN.MATTJAMES]: MATTJAMES_HARVEST_PLAN,
  [paths.HARVEST_PLAN.BRIGHTLAND]: BRIGHTLAND_HARVEST_PLAN,
  [paths.HARVEST_PLAN.KNOT]: KNOT_HARVEST_PLAN,
  [paths.HARVEST_PLAN.KNOT_SIZE]: KNOT_HARVEST_PLAN,
  [paths.HARVEST_PLAN.THRIVE]: THRIVE_HARVEST_PLAN,
  [paths.HARVEST_PLAN.COSTCO]: COSTCO_HARVEST_PLAN,
  [`${paths.HARVEST_PLAN.AMAZON}/*redemptionCode`]: AMAZON_HARVEST_PLAN,
};

const HarvestPlanProduct = ({ path, farmstandSize, content, redemptionCode }) => {
  const contentConfig = mapContentByPartner[path] ? mapContentByPartner[path] : { label: '' };
  const apiContent = useContent(contentConfig);
  const history = useHistory();

  const fields = content ? content?.fields : apiContent?.[0]?.fields;
  const sectionsData = fields?.sections?.map((e) => ({ ...e.fields, id: e?.sys?.id || '', history }));
  const catalog = useCatalog();
  const isLoading = !fields || !sectionsData?.length || !Object.values(catalog?.farmstands)?.length;

  useStopLoading(!isLoading);

  if (isLoading) return null;

  return (
    <>
      <Head
        title={fields?.metaTitle}
        description={fields?.metaDescription}
        ogImage={fields?.metaFogImage?.fields?.file?.url}
        twitterImage={fields?.metaTwitterImage?.fields?.file?.url}
        canonicalRoute={path}
      />
      <HarvestPlanProductStyled>
        <SectionProvider
          sectionContent={fields.sections}
          customSectionMap={pageSpecificMap(path || `/${fields.slug}`, farmstandSize, redemptionCode)}
        />
      </HarvestPlanProductStyled>
    </>
  );
};

HarvestPlanProduct.propTypes = {
  history: PropTypes.object,
  path: PropTypes.string,
  farmstandSize: PropTypes.string,
  redemptionCode: PropTypes.string,
  content: PropTypes.shape({
    fields: PropTypes.shape({
      sections: PropTypes.array,
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.string,
      metaFogImage: PropTypes.object,
      metaTwitterImage: PropTypes.object,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      bundleSelectionIndex: PropTypes.number,
    }),
  }),
};

export default HarvestPlanProduct;

import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const BannerCallOutStyled = styled.section.attrs({
  className: 'BannerCallOutStyled',
})`
  .ContentSection {
    padding: 3rem 2rem;
    background-color: white;

    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      .SectionInner {
        max-width: 62rem;
      }
    }
  }
`;

export const BannerCallOutInner = styled.div.attrs({
  className: 'BannerCallOutInner',
})`
  max-width: 24rem;
  margin: auto;

  @media ${breakPoints.large_break} {
    max-width: 30rem;
  }

  > .Icon {
    width: 7rem;
    margin: 0 auto 1rem;
    height: 1.5rem;
  }

  .Title {
    letter-spacing: normal;
    margin-bottom: 0.5rem;
  }

  > .Link {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 1.5rem auto 0;
  }
`;

export const LinkGroup = styled.ul.attrs({
  className: 'LinkGroup',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const LinkItem = styled.li.attrs({
  className: 'LinkItem',
})`
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.5rem;

  .Icon {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  smoothieSection: ({ theme }) => ({
    styles: `
      .ContentSection {
        background-color: ${theme.farmProject.light_green_4};
        .SectionInner {
          .SliderWrapper {
            margin-top: 2rem;
            @media ${breakPoints.large_break} {
              margin-top: 3rem;
            }
          }
          .SeedlingImageWrapper {
            .Image {
              width: 100%;
              height: 17rem;
              max-width: 26rem;
              picture {
                img {
                  min-width: 26rem;
                }
              }
            }
            @media ${breakPoints.large_break} {
              margin-right: 1.5rem;
              .Image {
                width: 25rem;
                height: 17rem;
              }
            }
          }
          .SeedlingInfoWrapper {
            margin-top: 1.5rem;
          }
          @media ${breakPoints.large_break} {
            .SeedlingInfoWrapper {
              margin-top: 0;
            }
          }
        }
      }
    `,
  }),
};

export const FeaturedSeedlingsStyled = styled.section.attrs({
  className: 'FeaturedSeedlingsStyled',
})`
  .ContentSection {
    padding: 3rem 1rem;
    background-color: white;

    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      padding: 5rem 2rem;

      .SectionInner {
        max-width: 62rem;
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const FeaturedSeedlingsInner = styled.section.attrs({
  className: 'FeaturedSeedlingsInner',
})`
  .Title {
    letter-spacing: normal;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
    color: ${({ theme }) => theme.farmProject.dark_green2};
  }

  .Text {
    font-size: 1.125rem;
    line-height: 1.4rem;
    font-weight: lighter;
  }

  @media ${breakPoints.large_break} {
    > .Title {
      font-size: 3rem;
    }

    > .Text {
      max-width: 50rem;
      margin: auto;
    }
  }
`;

export const SeedlingWrapper = styled.div.attrs({
  className: 'SeedlingWrapper',
})``;

export const SeedlingInner = styled.div.attrs({
  className: 'SeedlingInner',
})`
  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 54rem;
    margin: auto;
  }
`;

export const SeedlingImageWrapper = styled.div.attrs({
  className: 'SeedlingImageWrapper',
})`
  .Image {
    height: 14rem;
    width: 14rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    .Image {
      width: 25rem;
      height: 25rem;
    }
  }
`;

export const SeedlingInfoWrapper = styled.div.attrs({
  className: 'SeedlingInfoWrapper',
})`
  margin-top: 1rem;

  .Title {
    color: ${({ theme }) => theme.farmProject.dark_green2};
    font-size: 1.75rem;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: normal;
  }

  .Title + .Title {
    color: ${({ theme }) => theme.farmProject.dark_gray};
    font-weight: lighter;
    font-size: 1.75rem;
  }

  .Text {
    font-size: 0.875rem;
  }

  .Link {
    font-size: 0.875rem;
    width: fit-content;
    margin: auto;
    margin-top: 1rem;
    display: flex;
  }

  @media ${breakPoints.large_break} {
    width: 50%;

    .Title {
      text-align: left;
    }

    .Title + .Title {
      font-size: 2rem;
    }

    .Text {
      text-align: left;
    }

    .Link {
      margin-left: 0;
    }
  }
`;

export const SliderWrapper = styled.div.attrs({
  className: 'SliderWrapper',
})`
  margin-top: 1.5rem;

  .swiper-container {
    .swiper-button-prev {
      top: 47%;
      left: -3px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/left.svg');
      }
    }

    .swiper-button-next {
      top: 47%;
      right: 6px;
      width: 1.8rem;
      height: 1.6rem;

      &::after {
        width: 2rem;
        height: 2rem;
        content: url('/icons/right.svg');
      }
    }
  }
`;

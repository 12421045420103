import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';

import { HarvestProductMain, Loading, Flex } from 'components';
import { SquaredButton, Text, Input, ButtonText } from 'elements';
import breakPoints from 'theme/Media';

import { useBundleSeedlings } from 'utils/hooks';
import { getContentfulEntryLgType } from 'utils/contentful-utils';
import { getCustomBundleSeedlingsNames } from 'utils/productBundlesUtils';

import { LgType } from 'constants/LgType';

import { openCartModal, addBulkItemsAndDiscountArray } from 'app/reduxState/cart';
import {
  getRedemptionSkus,
  getPlantBundlesBySkus,
  fetchRedemptionCode,
  getIsRedemptionCodeFetching,
  getRedemptionCredits,
} from 'app/reduxState/catalog';

import HarvestProductSection from '../harvest-product-section/HarvestProductSection';

export const RedemptionCodeWrapper = styled(Flex)`
  gap: 1rem;

  .InputStyled {
    margin-bottom: 0;
    input {
    }
  }
`;

export const RedemptionFormWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

export const SeedlingListWrapper = styled.div.attrs({
  className: 'SeedlingListWrapper',
})`
  .Text {
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    margin: 1rem 0;
    color: ${({ theme }) => theme.farmProject.dark_gray_2};
  }

  @media ${breakPoints.large_break} {
    min-height: 6.5rem;
  }
`;

const RedemptionSection = ({ bundlesSeedlings, redemptionSection, onAddToCart, isLoading, redemptionCode }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (redemptionCode) {
      dispatch(fetchRedemptionCode({ redemptionCode }));
    }
  }, []);

  const isRedemptionCodeFetching = useSelector(getIsRedemptionCodeFetching);

  return (
    <>
      <Text
        content={bundlesSeedlings ? redemptionSection.fields?.body2 : redemptionSection.fields?.body}
        modifiers={['brandFont', 'light']}
        isHTML
      />
      {
        <RedemptionFormWrapper>
          {(isRedemptionCodeFetching || isLoading) && <Loading />}
          <Formik initialValues={{ redemptionCode: redemptionCode ? redemptionCode : '' }}>
            {({ setFieldValue, values, setFieldError }) => {
              return (
                !isRedemptionCodeFetching &&
                !isLoading && (
                  <Form>
                    {!bundlesSeedlings && (
                      <RedemptionCodeWrapper modifiers={['alignEnd', 'justifyLeft']}>
                        <Field
                          component={Input}
                          modifiers={['smallMargin', 'clear', 'compact']}
                          onChange={(e) => {
                            setFieldValue('redemptionCode', e.target.value);
                          }}
                          name='redemptionCode'
                          value={values.redemptionCode}
                        />
                        <ButtonText
                          modifiers={['underlineContent', 'compact', 'secondary', 'fontFamilyText']}
                          onClick={() => {
                            dispatch(fetchRedemptionCode({ redemptionCode: values.redemptionCode, setFieldError }));
                          }}
                        >
                          SUBMIT
                        </ButtonText>
                      </RedemptionCodeWrapper>
                    )}
                  </Form>
                )
              );
            }}
          </Formik>
        </RedemptionFormWrapper>
      }
      {bundlesSeedlings && (
        <SeedlingListWrapper>
          <Text modifiers={['brandFont', 'small', 'midGrayColor']}>{bundlesSeedlings}</Text>
        </SeedlingListWrapper>
      )}
      {bundlesSeedlings && <SquaredButton modifiers='tertiary' label='ADD TO CART' onClick={onAddToCart} />}
    </>
  );
};

RedemptionSection.propTypes = {
  bundlesSeedlings: PropTypes.array,
  redemptionSection: PropTypes.array,
  onAddToCart: PropTypes.func,
  isLoading: PropTypes.bool,
  redemptionCode: PropTypes.string,
};

const HarvestRedemptionShop = ({ title, body, sections = [], redemptionCode }) => {
  const dispatch = useDispatch();

  const envZipData = sections.find((section) => getContentfulEntryLgType(section) === LgType.HARVEST.ZIP)?.fields;
  const redemptionSection = sections.find((section) => getContentfulEntryLgType(section) === LgType.HARVEST.REDEMPTION_INPUT);

  const seedlingsCatalog = useSelector(({ catalog }) => catalog.seedlings);

  const redemptionSkus = useSelector(getRedemptionSkus);
  const redemptionCredits = useSelector(getRedemptionCredits);
  const redemptionBundles = useSelector((state) => getPlantBundlesBySkus(state, redemptionSkus));

  const { seedlings, isFetching: isLoading } = useBundleSeedlings(_.values(redemptionBundles)?.[0]?.sku, true);
  let bundleSeedlingsNames = getCustomBundleSeedlingsNames(seedlings, seedlingsCatalog);

  const onAddToCart = () => {
    if (!_.isEmpty(redemptionBundles)) {
      const items = redemptionSkus.map((sku) => {
        const redemptionBundlesBySku = Object.fromEntries(
          Object.keys(redemptionBundles).map((bundle) => [redemptionBundles[bundle].sku, redemptionBundles[bundle]])
        );
        return { ...redemptionBundlesBySku[sku], qty: 1 };
      });
      dispatch(addBulkItemsAndDiscountArray({ items, discount: [...redemptionCredits] }));
      dispatch(openCartModal());
    }
  };

  const sectionData = {
    mediaBackground: redemptionSection?.fields?.mediaBackground,
    title: redemptionSection?.fields?.title,
  };

  return (
    <HarvestProductSection title={title} body={body} envZipData={envZipData}>
      <HarvestProductMain
        sectionData={sectionData}
        shouldHidePlantsList={true}
        renderRedemptionSection={() => {
          return (
            <RedemptionSection
              bundlesSeedlings={bundleSeedlingsNames}
              redemptionSection={redemptionSection}
              onAddToCart={onAddToCart}
              isLoading={isLoading}
              redemptionCode={redemptionCode}
            />
          );
        }}
      />
    </HarvestProductSection>
  );
};

HarvestRedemptionShop.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  sections: PropTypes.array,
  redemptionCode: PropTypes.string,
};

export default HarvestRedemptionShop;

import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  smallFontQuestion: () => ({
    styles: `
      .QuoteSectionInner {
        .QuoteWrapper {
          .Title {  
            font-size: 2rem;
          } 
        }
      }
    `,
  }),
};

export const QuoteSectionStyled = styled.section.attrs({
  className: 'QuoteSectionStyled',
})`
  .ContentSection {
    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      padding: 5rem 2rem;

      .SectionInner {
        max-width: 62rem;
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const QuoteSectionInner = styled.div.attrs({
  className: 'QuoteSectionInner',
})`
  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PictureWrapper = styled.div.attrs({
  className: 'PictureWrapper',
})`
  display: block;
  width: 100%;
  height: auto;

  @media ${breakPoints.large_break} {
    width: calc(50% - 1rem);
  }

  .Image {
    picture {
      img {
        width: 100%;
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }
`;

export const QuoteWrapper = styled.div.attrs({
  className: 'QuoteWrapper',
})`
  margin-top: 2rem;
  width: 100%;

  > .Icon {
    width: 1.25rem;
    height: 2rem;

    svg {
      g {
        g {
          path {
            fill: ${({ theme }) => theme.farmProject.green};
          }
        }
      }
    }
  }

  .Title {
    font-size: 2.25rem;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 1rem;
  }

  .Text {
    text-align: left;

    &:first-of-type {
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }

    &:last-of-type {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }

  .IconLinkWrapper {
    &:last-of-type {
      .Icon {
        svg {
          width: 1rem;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    width: calc(50% - 1rem);

    .Title {
      font-size: 3rem;
    }

    .Text {
      &:last-of-type {
        font-size: 1.5rem;
        line-height: 1.4;
      }
    }
  }
`;

export const IconLinkWrapper = styled.div.attrs({
  className: 'IconLinkWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;

  .Icon {
    height: 1.5rem;
    width: 1.75rem;
    margin-right: 0.25rem;

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Title, Text, Link, Icon } from 'elements';

import { BannerCallOutStyled, BannerCallOutInner, LinkGroup, LinkItem } from './CallOutBanner.styled';

import Britco from 'elements/icon/assets/brit-and-co-logo.svg';
import Spotify from 'elements/icon/assets/spotify.svg';
import Apple from 'elements/icon/assets/apple-podcast.svg';
import Google from 'elements/icon/assets/google-podcasts.svg';

const iconMapping = {
  '4mOLJ6yrH6J38kJnhYyOk0': <Spotify />,
  '66qXSq6EFXi8i8F3V4TkIs': <Apple />,
  '5vvSsbMrSpsnXhLIoVqghq': <Google />,
};

const BannerCallOut = ({ title, body, links, callToAction }) => {
  const { linkText: interviewText, linkUrl: interviewLink, target: interviewTarget } = callToAction?.fields || {};

  const renderIconLink = (item, key) => {
    return (
      <LinkItem key={key}>
        <Icon>
          <Link target={item?.fields?.target} href={item?.fields?.linkUrl}>
            {iconMapping[item?.sys?.id]}
          </Link>
        </Icon>
      </LinkItem>
    );
  };

  return (
    <BannerCallOutStyled>
      <ContentSection>
        <BannerCallOutInner>
          <Icon>
            <Britco />
          </Icon>
          <Title as='h2' content={title} modifiers='tertiarySmall' />
          <Text content={body} />
          <Link target={interviewTarget} modifiers={['brandColor', 'underline']} content={interviewText} href={interviewLink} />
          <LinkGroup>{links?.map?.(renderIconLink)}</LinkGroup>
        </BannerCallOutInner>
      </ContentSection>
    </BannerCallOutStyled>
  );
};

BannerCallOut.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  links: PropTypes.array,
  callToAction: PropTypes.object,
};

export default BannerCallOut;

import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Title, Text, Icon } from 'elements';

import { RecipeSectionStyled, RecipeSectionInner, RecipeQuote, RecipeList, RecipeItem } from './RecipeSection.styled';
import RecipeCard from './RecipeCard';

import Quote from 'elements/icon/assets/quote.svg';

const RecipeSection = ({ title, body, quote, recipes = [] }) => {
  const renderRecipe = (item, key) => {
    const { title, author, bodyCopy, cookTime, prepTime, slug, mainImage, imageGallery } = item?.fields || {};
    const images = imageGallery?.length ? imageGallery : [mainImage];
    const authorName = author?.fields?.name;
    const time = `${cookTime + prepTime} MIN`;

    return (
      <RecipeItem key={key}>
        <RecipeCard title={title} body={bodyCopy} images={images} author={authorName} time={time} slug={slug} />
      </RecipeItem>
    );
  };

  return (
    <RecipeSectionStyled id='recipes'>
      <ContentSection>
        <RecipeSectionInner>
          <Title as='h2' content={title} modifiers={['tertiarySmall', 'brandColor']} />
          <Text modifiers={['brandFont', 'xLarge']} content={body} />
          {quote && <RecipeQuote>
            <Icon>
              <Quote />
            </Icon>
            <Text content={quote?.body} />
            <Text modifiers={['brandFont', 'xLarge']} content={quote?.title} />
          </RecipeQuote>}
          <RecipeList>{recipes?.map(renderRecipe)}</RecipeList>
        </RecipeSectionInner>
      </ContentSection>
    </RecipeSectionStyled>
  );
};

RecipeSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  quote: PropTypes.object,
  recipes: PropTypes.array,
};

export default RecipeSection;

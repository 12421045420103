import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const RecipeSectionStyled = styled.section.attrs({
  className: 'RecipeSectionStyled',
})`
  .ContentSection {
    padding: 3rem 1rem;

    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      padding: 5rem 2rem;

      .SectionInner {
        max-width: 62rem;
      }
    }
  }
`;

export const RecipeSectionInner = styled.div.attrs({
  className: 'RecipeSectionInner',
})`
  margin: auto;

  > .Icon {
    width: 7rem;
    margin: 0 auto 1rem;
    height: 1.5rem;
  }

  .Title {
    letter-spacing: normal;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
  }

  > .Link {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: 1.5rem auto 0;
  }

  > .Text {
    max-width: 50rem;
    margin: auto;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 3rem;
    }
  }
`;

export const RecipeQuote = styled.div.attrs({
  className: 'RecipeQuote',
})`
  padding: 1.5rem 1.25rem;
  border: solid 1px ${({ theme }) => theme.farmProject.dark_green2};
  border-radius: 0.25rem;
  position: relative;
  z-index: 1;
  max-width: 47rem;
  margin: auto;
  margin-top: 2rem;

  .Text {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .Text + .Text {
    margin-top: 0.5rem;
  }

  .Icon {
    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    top: -0.25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.farmProject.gray_3};
    z-index: 11;
    border-radius: 50%;

    svg {
      max-width: 1.5rem;

      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }
`;

export const RecipeList = styled.ul.attrs({
  className: 'RecipeList',
})`
  margin-top: 2rem;

  @media ${breakPoints.large_break} {
    margin-top: 3rem;

    .RecipeItem {
      &:nth-child(even) {
        .RecipeCardStyled {
          flex-direction: row-reverse;
        }
      }
    }
  }
`;

export const RecipeItem = styled.li.attrs({
  className: 'RecipeItem',
})`
  margin: 1.5rem 0;
`;

export const RecipeCardStyled = styled.div.attrs({
  className: 'RecipeCardStyled',
})`
  max-width: 33rem;
  margin: auto;

  @media ${breakPoints.large_break} {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    max-width: 100%;
  }
`;

export const SliderWrapper = styled.div.attrs({
  className: 'SliderWrapper',
})`
  .SwiperImageWrapper {
    .ImageWrapper {
      height: 20rem;

      .Image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        picture {
          img {
            width: auto;
            height: 120%;
          }
        }
      }

      @media ${breakPoints.large_break} {
        height: 26rem;
      }
    }
  }
  @media ${breakPoints.large_break} {
    width: 50%;
    flex-shrink: 0;
  }
`;

export const RecipeInfoWrapper = styled.div.attrs({
  className: 'RecipeInfoWrapper',
})`
  background-color: white;
  padding: 1.5rem 1rem;

  @media ${breakPoints.large_break} {
    width: 50%;
    padding: 1.5rem 1.5rem;
  }

  .Title {
    font-size: 2rem;
    text-align: left;
    margin-bottom: 0.25rem;
  }

  .Text {
    text-align: left;
    line-height: 1.5;
  }

  .Text + .Text {
    margin: 0.5rem 0 1rem;
  }

  .Link {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

export const TimeWrapper = styled.div.attrs({
  classNames: 'TimeWrapper',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .Icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;

    svg {
      path {
        fill: ${({ theme }) => theme.farmProject.green};
      }
    }
  }

  .Text {
    color: ${({ theme }) => theme.farmProject.green};
    font-weight: 500;
  }
`;

import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HarvestProductMain } from 'components';

import { useBundleSeedlings } from 'utils/hooks';
import { getContentfulEntryType, getContentfulEntryLgType } from 'utils/contentful-utils';
import { formatPrice } from 'utils/cart-utils';
import {
  getBundlesBySkuSelector,
  getBundleSeedlingsNames,
  getSelectedBundlePlantsLinks,
  getCustomBundleSeedlingsNames,
} from 'utils/productBundlesUtils';

import { LgType } from 'constants/LgType';
import ShopCategories from 'constants/shopCategories';

import { addBulkItems, openCartModal } from 'app/reduxState/cart';

import HarvestProductSection from '../harvest-product-section/HarvestProductSection';
import { TOP_PICK_FARM_SIZE, BUNDLE_CONTENT_TYPE, BUNDLE_MIX_CONTENT_TYPE } from '../../constants/productBundlesConstants';

const HarvestProductBundlesSection = ({ title, body, sections = [], farmstandSize = null }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const bundleSelectionIndex = location?.state?.bundleSelectionIndex || 0;

  const [selectedSize, setSelectedSize] = useState(farmstandSize || TOP_PICK_FARM_SIZE);
  const [selectedOption, setSelectedOption] = useState(bundleSelectionIndex);

  const seedlingsCatalog = useSelector(({ catalog }) => catalog.seedlings);
  const envZipData = sections.find((section) => getContentfulEntryLgType(section) === LgType.HARVEST.ZIP)?.fields;
  const seedlingPlanSections = sections.filter((section) => getContentfulEntryLgType(section) === LgType.HARVEST.SEEDLING_PLAN);

  const contentfulBundles =
    seedlingPlanSections[selectedOption]?.fields?.percentages?.filter((percentage) => {
      return getContentfulEntryType(percentage) === BUNDLE_CONTENT_TYPE || getContentfulEntryType(percentage) === BUNDLE_MIX_CONTENT_TYPE;
    }) || [];
  const contentfulSkus = contentfulBundles.map((e) => {
    return e.sys.id;
  });

  const contentfulBundlesFromCatalog = useSelector(getBundlesBySkuSelector(contentfulSkus));
  const selectedBundle = contentfulBundlesFromCatalog?.[selectedSize];
  const shouldFetch = selectedBundle?.category === ShopCategories.CUSTOM_BUNDLES;
  const { seedlings } = useBundleSeedlings(selectedBundle?.sku, shouldFetch);
  const bundleSeedlingsSkus = seedlings;

  let bundleSeedlingsNames;
  if (shouldFetch) {
    bundleSeedlingsNames = getCustomBundleSeedlingsNames(bundleSeedlingsSkus, seedlingsCatalog);
  } else {
    bundleSeedlingsNames = getBundleSeedlingsNames(getSelectedBundlePlantsLinks(contentfulBundles, selectedBundle), seedlingsCatalog);
  }

  const availableSizes = contentfulBundles.map(({ fields }) => fields?.plantCount);

  const buttonPrice = useMemo(() => formatPrice(selectedBundle?.priceCents / 100, 0), [selectedBundle?.priceCents]);

  const toggleFirstLabel = seedlingPlanSections[0]?.fields?.lowerTitle;
  const toggleSecondLabel = seedlingPlanSections[1]?.fields?.lowerTitle;
  const sectionData = seedlingPlanSections[selectedOption]?.fields;

  const onAddToCart = () => {
    if (selectedBundle) {
      const items = [{ ...selectedBundle, qty: 1 }];
      dispatch(addBulkItems({ items }));
      dispatch(openCartModal());
    }
  };

  const onSelectSize = (newSize) => setSelectedSize(newSize);
  const onToggleOption = (newOption) => setSelectedOption(newOption);

  useEffect(() => {
    if (typeof farmstandSize === 'number' && farmstandSize !== selectedSize) onSelectSize(farmstandSize);
    if (typeof bundleSelectionIndex === 'number' && bundleSelectionIndex !== selectedOption) onToggleOption(bundleSelectionIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmstandSize, bundleSelectionIndex]);

  if (!Object.keys(contentfulBundlesFromCatalog)?.length || !Object.values(seedlingsCatalog)?.length) return null;

  return (
    <HarvestProductSection title={title} body={body} envZipData={envZipData}>
      <HarvestProductMain
        topPickSize={TOP_PICK_FARM_SIZE}
        bundlesSeedlings={bundleSeedlingsNames}
        toggleFirstLabel={toggleFirstLabel}
        toggleSecondLabel={toggleSecondLabel}
        buttonPrice={buttonPrice}
        selectedSize={selectedSize}
        selectedOption={selectedOption}
        sectionData={sectionData}
        availableSizes={availableSizes}
        onSelectSize={onSelectSize}
        onToggleOption={onToggleOption}
        onAddItemsToCart={onAddToCart}
      />
    </HarvestProductSection>
  );
};

HarvestProductBundlesSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  sections: PropTypes.array,
  slug: PropTypes.string,
  farmstandSize: PropTypes.number,
  selectedBundleIndex: PropTypes.number,
};

export default HarvestProductBundlesSection;

import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection, Swiper } from 'components';
import { Title, Text } from 'elements';

import { FeaturedSeedlingsStyled, FeaturedSeedlingsInner, SliderWrapper } from './FeaturedSeedlings.styled';

import Seedling from './Seedling';

const FeaturedSeedlings = ({ title, subtitle, labelTip, seedlings, isRecipe, body, modifiers }) => {
  const onRenderSeedlingCard = (item, key) => {
    const { friendlyName, description, introSentence, healthBenefitsImage, title, slug, mainImage, emailTitle } = item?.fields || {};
    const imageInfo = isRecipe ? mainImage : healthBenefitsImage;
    return (
      <Seedling
        key={key}
        title={isRecipe ? title : friendlyName}
        body={isRecipe ? introSentence : description || introSentence}
        image={imageInfo?.fields?.file?.url}
        imageAlt={imageInfo?.fields?.description}
        labelTip={isRecipe ? emailTitle : labelTip}
        slug={isRecipe ? slug : null}
      />
    );
  };

  return (
    <FeaturedSeedlingsStyled modifiers={modifiers}>
      <ContentSection>
        <FeaturedSeedlingsInner>
          <Title as='h2' content={title} modifiers='tertiarySmall' />
          <Text content={isRecipe ? body : subtitle} modifiers={['brandFont']} />
          <SliderWrapper>
            <Swiper items={seedlings} swiperProps={{ navigation: true }} renderSlideCard={onRenderSeedlingCard} />
          </SliderWrapper>
        </FeaturedSeedlingsInner>
      </ContentSection>
    </FeaturedSeedlingsStyled>
  );
};

FeaturedSeedlings.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  labelTip: PropTypes.string,
  seedlings: PropTypes.array,
  isRecipe: PropTypes.bool,
  body: PropTypes.string,
  modifiers: PropTypes.any,
};

export default FeaturedSeedlings;

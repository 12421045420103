import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Text } from 'elements';

import { TermsAndConditionsStyled, TermsInner } from './TermsAndConditions.styled';

const TermsAndConditions = ({ body }) => {
  return (
    <TermsAndConditionsStyled>
      <ContentSection>
        <TermsInner>
          <Text content={body} isHTML />
        </TermsInner>
      </ContentSection>
    </TermsAndConditionsStyled>
  );
};

TermsAndConditions.propTypes = {
  body: PropTypes.string,
};

export default TermsAndConditions;

import { getPlantBundlesBySkus } from 'reduxState/catalog';
import { getSeedlingsListFromCustomBundle } from 'utils/bundles-utils';
import { getFarmSizeFromName } from 'utils/string-utils';
import { nameSort } from 'utils/seedlings-utils';

export const getBundlesBySkuSelector = (bundlesSkus) => (state) => getPlantBundlesBySkus(state, bundlesSkus);

export const getSelectedBundlePlantsLinks = (bundles = [], selectedBundle = {}) => {
  return bundles?.find?.(({ sys }) => sys.id === selectedBundle?.sku)?.fields?.plantsLinks;
};

export const getFarmstandCatalogBySize = (farmstandsCatalog, farmSize) => {
  return farmstandsCatalog?.find?.((farm) => getFarmSizeFromName(farm) === farmSize?.toString?.()) || {};
};

export const getBundleSeedlingsNames = (plantsLinks, catalog) => {
  const skus = plantsLinks?.map((e) => e.sys.id);
  return getSeedlingsListFromCustomBundle(skus, plantsLinks, catalog)
    .sort(nameSort)
    .map(({ name, qty }) => `(${qty}) ${name}`)
    .join(' · ');
};

export const getCustomBundleSeedlingsNames = (seedlingsList = [], seedlingsCatalog = {}) => {
  return getSeedlingsListFromCustomBundle(seedlingsList, null, seedlingsCatalog)
    ?.sort(nameSort)
    ?.map(({ name, qty }) => `(${qty}) ${name}`)
    ?.join(' · ');
};

import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection, ZipCodeEditor, GrowEnvironmentToggle } from 'components';
import { Title, Text } from 'elements';

import { HarvestProductSectionStyled, HarvestProducInner, ZipCodeWrapper, EnvToggle } from './HarvestProductSection.styled';

const HarvestProductSection = ({ title, body, envZipData, children }) => {
  return (
    <HarvestProductSectionStyled id='harvest'>
      <ContentSection>
        <HarvestProducInner>
          <Title as='h2' content={title} modifiers={['tertiarySmall']} />
          <Text content={body} modifiers={['brandFont', 'xLarge']} />
          {!!envZipData && (
            <>
              <ZipCodeWrapper>
                <Text modifiers={['brandFont']}>{envZipData?.title || ''}</Text>
                <ZipCodeEditor modifiers='medium' shouldHideLabel />
              </ZipCodeWrapper>
              <EnvToggle>
                <GrowEnvironmentToggle title={envZipData?.body || ''} modifiers='justifyCenter' />
              </EnvToggle>
            </>
          )}
          {children}
        </HarvestProducInner>
      </ContentSection>
    </HarvestProductSectionStyled>
  );
};

HarvestProductSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  envZipData: PropTypes.object,
  children: PropTypes.node,
};

export default HarvestProductSection;

import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const TermsAndConditionsStyled = styled.section.attrs({
  className: 'TermsAndConditionsStyled',
})`
  .ContentSection {
    padding: 2rem 2rem;
    background-color: ${({ theme }) => theme.farmProject.gray_1};

    @media ${breakPoints.medium_break} {
      .SectionInner {
        max-width: 33.5rem;
        margin: auto;
      }
    }

    @media ${breakPoints.large_break} {
      .SectionInner {
        max-width: 62rem;
      }
    }
  }
`;

export const TermsInner = styled.div.attrs({
  className: 'TermsInner',
})`
  .Text {
    text-align: left;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Title, Text, Image } from 'elements';

import { HeroFeaturedStyled, HeroFeaturedInner, ImageHeroWrapper, TextWrapper, LabelFeatured, LogoWrapper } from './HeroFeatured.styled';

const HeroFeatured = ({ title, body, imageContent, modifiers }) => {
  const { mediaContent, mediaContent2, headline, body: subtitle } = imageContent?.fields || {};
  const logoPartner = mediaContent2?.fields;

  return (
    <HeroFeaturedStyled modifiers={modifiers}>
      <ContentSection>
        <HeroFeaturedInner>
          <ImageHeroWrapper>
            <Image
              picture={mediaContent?.fields?.file?.url}
              alt={mediaContent?.fields?.description}
            />
            {!!logoPartner && (
              <LogoWrapper>
                <Image
                  picture={logoPartner?.file?.url}
                  alt={logoPartner?.description}
                />
              </LogoWrapper>
            )}
            {!!headline && (
              <LabelFeatured>
                <Text content={headline} modifiers={['brandFont']} isHTML />
                <Text content={subtitle} isHTML />
              </LabelFeatured>
            )}
          </ImageHeroWrapper>
          <TextWrapper>
            <Title as='h2' content={title} modifiers='tertiarySmall' />
            <Text content={body} modifiers={['brandFont', 'light']} isHTML />
          </TextWrapper>
        </HeroFeaturedInner>
      </ContentSection>
    </HeroFeaturedStyled>
  );
};

HeroFeatured.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  imageContent: PropTypes.object,
  modifiers: PropTypes.any,
};

export default HeroFeatured;

import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  lightGreenBG: ({ theme }) => ({
    styles: ` 
      .ContentSection {
        background-color: ${theme.farmProject.light_green_5};
      }
    `,
  }),
};

export const HeroFeaturedStyled = styled.section.attrs({
  className: 'HeroFeaturedStyled',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.light_green_3};
    padding: 0;
  }

  ${applyStyleModifiers(modifiers)};
`;

export const HeroFeaturedInner = styled.div.attrs({
  className: 'HeroFeaturedInner',
})`
  .Title {
    letter-spacing: normal;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
  }

  .Text {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: lighter;
  }

  @media ${breakPoints.large_break} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export const ImageHeroWrapper = styled.div.attrs({
  className: 'ImageHeroWrapper',
})`
  position: relative;

  .Image {
    max-height: 30rem;

    picture {
      img {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  @media ${breakPoints.large_break} {
    width: 50%;
    display: block;
    height: 62vh;
    position: relative;
    min-height: 30rem;

    .Image {
      height: 100%;
      position: absolute;
      max-height: 100%;

      picture {
        img {
          position: absolute;
          width: auto;
          height: 100%;
          min-height: 35rem;
        }
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    max-height: 33rem;

    .Image {
      picture {
        img {
          position: relative;
          width: 103%;
          height: auto;
          min-height: unset;
        }
      }
    }
  }
`;

export const TextWrapper = styled.div.attrs({
  className: 'TextWrapper',
})`
  padding: 2rem 1.25rem;
  max-width: 33rem;
  margin: auto;

  .Title {
    text-align: left;
    font-weight: 500;
  }

  .Text {
    text-align: left;
  }

  @media ${breakPoints.large_break} {
    width: 50%;
    max-width: 28rem;
    margin-right: 1rem;

    .Title {
      font-size: 4rem;
      line-height: 1.1;
      font-weight: 300;
    }
  }
`;

export const LabelFeatured = styled.div.attrs({
  className: 'LabelFeatured',
})`
  width: fit-content;
  background-color: ${({ theme }) => theme.farmProject.light_green_3};
  padding: 0.35rem 1rem;
  border: solid 1px ${({ theme }) => theme.farmProject.light_green_4};
  position: absolute;
  z-index: 10;
  bottom: 2rem;
  left: 1.25rem;

  .Text {
    font-size: 1rem;
    text-align: left;
    font-weight: 500;
    line-height: normal;
  }

  .Text + .Text {
    font-size: 0.875rem;
    margin-top: 0.125rem;
  }
`;

export const LogoWrapper = styled.div.attrs({
  className: 'LogoWrapper',
})`
  width: 5rem;
  height: 5rem;
  display: block;
  position: absolute;
  z-index: 10;
  bottom: 2rem;
  left: 1.25rem;

  .Image {
    picture {
      img {
        width: 100%;
        min-height: unset;
      }
    }
  }

  @media ${breakPoints.large_break} {
    width: 7rem;
    height: 7rem;
  }
`;
